import React, { useContext } from 'react'
import styles from './AuthClients.module.scss'
import { AuthClientsContext } from '../../providers/AuthClientContext'
import { ToastContext } from '../../providers/ToastContext'
import { updateAuthClient as apiUpdateAuthClient } from '../../api/api'
import TableV2 from '../../components/TableV2/TableV2'
import { createColumnHelper } from '@tanstack/react-table'
import TableCell from '../../components/TableV2/TableCell/TableCell'
import EditCell from '../../components/TableV2/EditCell/EditCell'
import { TableColumns, TableRow } from '../../components/TableV2/types/TableTypes'
import { Button } from 'react-bootstrap'
import { useModalContext } from '../../providers/ModalContext'
import { AuthClientRow } from './types'
import { mapAuthClientsToTableRows, mapTableRowToAuthClient } from '../../types/tableMapper/authClients'

const AuthClients = () => {
  const { authClients, updateAuthClient } = useContext(AuthClientsContext)
  const { addToast } = useContext(ToastContext)
  const { setModalContent, setModalPayload } = useModalContext()

  const handleSave = (index: number) => {
    const newAuthClient = authClients[index]

    apiUpdateAuthClient(newAuthClient)
      .then(() => { 
        updateAuthClient(newAuthClient)
        addToast({ message: 'Successfully updated authclient', variant: 'success' })
      })
      .catch((e) => {
        console.error(e)
        addToast({ message: 'Failed to update AuthClient details. Please try again.', variant: 'danger' })
      })
  }

  const handleRemove = (index: number) => {
    setModalPayload(authClients[index].id)
    setModalContent('confirmDelete')
  }

  const rows = mapAuthClientsToTableRows(authClients)

  const columnHelper = createColumnHelper<Partial<AuthClientRow>>()
  const columns = [
    columnHelper.accessor('clientId', {
      header: 'Client ID',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: false
      }
    }),
    columnHelper.accessor('clientName', {
      header: 'Client Name',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: true
      }
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: false
      }
    }),
    columnHelper.accessor('titleId', {
      header: 'Title ID',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: false
      }
    }),
    columnHelper.accessor('redirectUris', {
      header: 'Redirect URL',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: true
      }
    }),
    columnHelper.accessor('postLogoutRedirectUris', {
      header: 'Post Logout Redirect URL',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: true
      }
    }),
    columnHelper.display({
      id: 'edit',
      cell: EditCell
    })
  ] as TableColumns

  const openModal = () => setModalContent('createAuthClient')

  const handleChange = (index: number, updatedRow: TableRow) => {
    updateAuthClient({
      ...authClients[index],
      ...mapTableRowToAuthClient({
        authClients,
        index,
        tableRow: updatedRow as TableRow<AuthClientRow>
      })
    })
  }

  const getActionButtons = () =>
    <Button onClick={openModal} className={styles.authClientBtn}><i className={`bi bi-plus-lg ${styles.iconBtn}`}></i>Create AuthClient</Button>

  return (
    <div className={styles.authClients}>
      <h1>AuthClients</h1>
      <TableV2
        rows={rows}
        columns={columns}
        onSave={handleSave}
        onChange={handleChange}
        onRemove={handleRemove}
        actionButtons={getActionButtons()}
      />
    </div>
  )
}

export default AuthClients