import { AuthClient } from '../authClient'
import { TableRow } from '../../components/TableV2/types/TableTypes'
import { AuthClientRow } from '../../views/AuthClients/types'

export const mapAuthClientsToTableRows = (clients: AuthClient[]): TableRow<AuthClientRow>[] =>
  clients.map(client => ({
    id: client.id,
    title: client.title?.title ?? '',
    titleId: client.titleId ?? '',
    clientId: client.clientId ?? '',
    clientName: client.clientName ?? '',
    clientSecret: client.clientSecret ?? '',
    redirectUris: client.redirectUris ?? '',
    postLogoutRedirectUris: client.postLogoutRedirectUris ?? '',
  }))

export const mapTableRowToAuthClient = ({
  authClients,
  index,
  tableRow
}: {
  authClients: AuthClient[]
  index: number
  tableRow: TableRow<AuthClientRow>
}): AuthClient => ({
  ...authClients[index],
  title: authClients[index].title,
  titleId: authClients[index].titleId,
  clientId: tableRow.clientId,
  clientSecret: tableRow.clientSecret,
  clientName: tableRow.clientName,
  redirectUris: tableRow.redirectUris,
  postLogoutRedirectUris: tableRow.postLogoutRedirectUris
})